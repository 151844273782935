import { LocationStrategy, PathLocationStrategy } from "@angular/common";
import { NgModule, Type } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { environment } from "../../environments/environment";

const routes: Routes = [
	{
		path: "",
		loadChildren: () =>
			import("@cisco-dnas-dashboard/dashboard-lib").then(
				(m) => m.DashboardLibModule
			)
	}
];


@NgModule({
	providers: [
		{
			provide: LocationStrategy,
			useClass: PathLocationStrategy
		}
	],
	imports: [
		RouterModule.forRoot(routes, {
			enableTracing: environment.enableTracing, // <-- debugging purposes only
			urlUpdateStrategy: "eager",
			scrollPositionRestoration: "top",
			anchorScrolling: "enabled",
			paramsInheritanceStrategy: "always",
			preloadingStrategy: PreloadAllModules
			// useHash: true
		})
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
