/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import {
	CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders,
	NgModule, NO_ERRORS_SCHEMA, Optional,
	SkipSelf
} from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { DashboardLibModule } from "@cisco-dnas-dashboard/dashboard-lib";
import { CookieService, DataService, SafeHtmlPipe, SharedLibModule } from "@cisco-dnas-dashboard/shared-lib";
// import { TagInputModule } from "ngx-chips";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./routing/app-routing.module";

const components = [AppComponent];

@NgModule({
	declarations: components,
	imports: [
		BrowserModule,
		CommonModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
	providers: [
		DataService, 
		CookieService
	],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor(@Optional() @SkipSelf() parentModule: AppModule) {
		if (parentModule) {
			throw new Error(
				"AppModule is already loaded. Import it in the AppModule only"
			);
		}
	}

	static forRoot(): ModuleWithProviders<AppModule> {
		return {
			ngModule: AppModule,
			providers: [
				// {provide: Injector, useValue: injector }
			]
		};
	}
}
