import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import { WorkspaceBarComponent } from "@cisco-dnas-dashboard/behavior-metrics-lib";
import {
	CircularLoaderStatus,
	DataService, VersionCheckService
} from "@cisco-dnas-dashboard/shared-lib";
import { Subject } from "rxjs";
import { environment } from "../environments/environment";
import { normalizeCommonJSImport } from "./normalizeCommonJSImport";

const importDashboard = normalizeCommonJSImport(
	import(
		/* webpackChunkName: "@cisco-dnas-dashboard/dashboard-lib" */ "@cisco-dnas-dashboard/dashboard-lib"
	)
);

const importCaptivePortal = normalizeCommonJSImport(
	import(
		/* webpackChunkName: "@cisco-dnas-dashboard/captive-portal-lib" */ "@cisco-dnas-dashboard/captive-portal-lib"
	)
);

const importOpenRoaming = normalizeCommonJSImport(
	import(
		/* webpackChunkName: "@cisco-dnas-dashboard/openroaming-lib" */ "@cisco-dnas-dashboard/openroaming-lib"
	)
);

const importShared = normalizeCommonJSImport(
	import(
		/* webpackChunkName: "@cisco-dnas-dashboard/shared-lib" */ "@cisco-dnas-dashboard/shared-lib"
	)
);

const importMonitoringSupport = normalizeCommonJSImport(
	import(
		/* webpackChunkName: "@cisco-dnas-dashboard/monitoring-support-lib" */ "@cisco-dnas-dashboard/monitoring-support-lib"
	)
);

const importImpactAnalysis = normalizeCommonJSImport(
	import(
		/* webpackChunkName: "@cisco-dnas-dashboard/impact-analysis-lib" */ "@cisco-dnas-dashboard/impact-analysis-lib"
	)
);

const importLocationAnalytics = normalizeCommonJSImport(
	import(
		/* webpackChunkName: "@cisco-dnas-dashboard/location-analytics-lib" */ "@cisco-dnas-dashboard/location-analytics-lib"
	)
);

const importRightNow = normalizeCommonJSImport(
	import(
		/* webpackChunkName: "@cisco-dnas-dashboard/right-now-lib" */ "@cisco-dnas-dashboard/right-now-lib"
	)
);

const importVideoAnalytics = normalizeCommonJSImport(
	import(
		/* webpackChunkName: "@cisco-dnas-dashboard/video-analytics-lib" */ "@cisco-dnas-dashboard/video-analytics-lib"
	)
);

const importAdmin = normalizeCommonJSImport(
	import(
		/* webpackChunkName: "@cisco-dnas-dashboard/monitoring-support-lib" */ "@cisco-dnas-dashboard/admin-lib"
	)
);

const importPersonas = normalizeCommonJSImport(
	import(
		/* webpackChunkName: "@cisco-dnas-dashboard/personas-lib" */ "@cisco-dnas-dashboard/personas-lib"
	)
);

const importEngagements = normalizeCommonJSImport(
	import(
		/* webpackChunkName: "@cisco-dnas-dashboard/engagements-lib" */ "@cisco-dnas-dashboard/engagements-lib"
	)
);

const importSharedFramework = normalizeCommonJSImport(
	import(
		/* webpackChunkName: "@cisco-dnas-dashboard/share-framework-lib" */ "@cisco-dnas-dashboard/share-framework-lib"
	)
);

// const importEnergyUtilObj = normalizeCommonJSImport(
// 	import( /* webpackChunkName: "@cisco-dnas-dashboard/energy-utilization-lib" */ '@cisco-dnas-dashboard/energy-utilization-lib'
// 	)
// );

const importSignage = normalizeCommonJSImport(
	import( /* webpackChunkName: "@cisco-dnas-dashboard/signages-lib" */ '@cisco-dnas-dashboard/signage-lib'
	)
);

// const importEnvironmentalMetrics = normalizeCommonJSImport(
// 	import(
// 		/* webpackChunkName: "@cisco-dnas-dashboard/environmental-metrics-lib" */ "@cisco-dnas-dashboard/environmental-metrics-lib"
// 	)
// );

const importWorkspaceExperience = normalizeCommonJSImport(
	import(
		/* webpackChunkName: "@cisco-dnas-dashboard/environmental-metrics-lib" */ "@cisco-dnas-dashboard/workspace-experience-lib"
	)
);

const importXlsx = normalizeCommonJSImport(
	import(/* webpackChunkName: "xlsx" */ "xlsx")
);

const importNg2Ckeditor = normalizeCommonJSImport(
	import(/* webpackChunkName: "ng2-ckeditor" */ "ng2-ckeditor")
);

const importAngularTreeComponent = normalizeCommonJSImport(
	import(
		/* webpackChunkName: "@circlon/angular-tree-component" */ "@circlon/angular-tree-component"
	)
);

const importd3 = normalizeCommonJSImport(
	import(/* webpackChunkName: "d3" */ "d3")
);

const importd3Collection = normalizeCommonJSImport(
	import(/* webpackChunkName: "d3-collection" */ "d3-collection")
);

const importd3Sankey = normalizeCommonJSImport(
	import(/* webpackChunkName: "d3-sankey" */ "d3-sankey")
);

const importd3SelectionMulti = normalizeCommonJSImport(
	import(/* webpackChunkName: "d3-selection-multi" */ "d3-selection-multi")
);

const importd3Tip = normalizeCommonJSImport(
	import(/* webpackChunkName: "d3-tip" */ "d3-tip")
);

const importTracer = normalizeCommonJSImport(
	import(/* webpackChunkName: "@cisco-dnas-dashboard/tracer-lib" */ '@cisco-dnas-dashboard/tracer-lib'
	)
);

const importLocationAnalyticsV2 = normalizeCommonJSImport(
        import( /* webpackChunkName: "@cisco-dnas-dashboard/location-analytics-lib" */ '@cisco-dnas-dashboard/location-analytics-v2-lib'
        )
);

@Component({
	selector: "app-platform-root",
	templateUrl: "./app.component.html",
	styles: []
})
export class AppComponent implements OnInit, OnDestroy {
	private unsubscribe$ = new Subject<void>();

	importDashboardObj = null;
	importCaptivePortalObj = null;
	importOpenRoamingObj = null;
	importSharedObj = null;
	importMonitoringSupportObj = null;
	importImpactAnalysisObj = null;
	importLocationAnalyticsObj = null;
	importRightNowObj = null;
	importVideoAnalyticsObj = null;
	importAdminObj = null;
	importPersonasObj = null;
	importEngagementsObj = null;
	importSharedFrameworkObj = null;
	importSignageObj = null;
	importWorkspaceExperienceObj = null;

	importXlsxObj = null;
	importNg2CkeditorObj = null;
	importAngularTreeComponentObj = null;
	importd3Obj = null;
	importd3CollectionObj = null;
	importd3SankeyObj = null;
	importd3SelectionMultiObj = null;
	importd3TipObj = null;
	importTracerObj = null;

	constructor(
		private versionCheckService: VersionCheckService,
		public dataService: DataService
	) { }

	async ngOnInit(): Promise<void> {
		this.dataService.display(true);
		// Wait for dynamic module import resolution
		// Dashboard Libs
		this.importDashboardObj = await importDashboard;
		this.importCaptivePortalObj = await importCaptivePortal;
		this.importOpenRoamingObj = await importOpenRoaming;
		this.importSharedObj = await importShared;
		this.importMonitoringSupportObj = await importMonitoringSupport;
		this.importImpactAnalysisObj = await importImpactAnalysis;
		this.importLocationAnalyticsObj = await importLocationAnalytics;
		this.importRightNowObj = await importRightNow;
		this.importVideoAnalyticsObj = await importVideoAnalytics;
		this.importAdminObj = await importAdmin;
		this.importPersonasObj = await importPersonas;
		this.importEngagementsObj = await importEngagements;
		this.importSharedFrameworkObj = await importSharedFramework;
		this.importSignageObj = await importSignage;
		this.importWorkspaceExperienceObj = await importWorkspaceExperience;

		// External Libs
		this.importXlsxObj = await importXlsx;
		this.importNg2CkeditorObj = await importNg2Ckeditor;
		this.importAngularTreeComponentObj = await importAngularTreeComponent;
		this.importd3Obj = await importd3;
		this.importd3CollectionObj = await importd3Collection;
		this.importd3SankeyObj = await importd3Sankey;
		this.importd3SelectionMultiObj = await importd3SelectionMulti;
		this.importd3TipObj = await importd3Tip;
		this.importTracerObj = await importTracer

		setTimeout(() => {
			this.versionCheckService.doVersionCheck(
				environment.versionCheckURL,
				this.unsubscribe$
			);
		});

		console.log("init platform component ts");

	}

	@HostListener("window:focus", ["$event"])
	onFocus(event: FocusEvent): void {
		console.log("showLoader::window:focus", event);
		this.resetDisplayLoader();
	}

	@HostListener("window:blur", ["$event"])
	onBlur(event: FocusEvent): void {
		console.log("showLoader::window:blur", event);
		//this.resetDisplayLoader();
	}

	resetDisplayLoader = (): void => {
		const localUnsubscribe$ = new Subject<void>();

		this.dataService
			.getStatus(localUnsubscribe$)
			.subscribe((val: CircularLoaderStatus) => {
				// console.log('showLoader::', val, " before was: ", this.showLoader);
				localUnsubscribe$.next();
				localUnsubscribe$.complete();
				this.dataService.display(val.display, val.delay);
			});
	};

	ngOnDestroy(): void {
		console.log("destory App Component");
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
		// this.searchTextObserver.
	}
}
