import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import '@angular/compiler'
import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

if (!/(local|sandbox)/.test(document.location.host) && environment.production) {
	enableProdMode();
}

document.addEventListener("DOMContentLoaded", () => {
	platformBrowserDynamic()
		.bootstrapModule(AppModule)
		.then((ref) => {
			// Ensure Angular destroys itself on hot reloads.
			if (window["ngRef"]) {
				window["ngRef"].destroy();
			}
			window["ngRef"] = ref;
			// Otherise, log the boot error
		})
		.catch((err) =>
			console.log("bootstrapWorkerUi hot reload failed.", err)
		);
});
